<template lang="pug">
.step-field-mapping
  om-toast.mb-4(:closable="false" type="icon" color="warning")
    div {{ $t('integrationWarnings.only_firstname_lastname_email_phone') }}

  .title {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'Shopify' }) }}
  .d-flex.fields-wrapper.w-100
    .d-flex.fields-title
      .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
      .arrow-block
      .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'Shopify' }) }}
    .d-flex.fields(v-for="(binding, index) in formBindings")
      binding-row.w-100.justify-content-between(
        :isNewFlow="true"
        :binding="binding"
        :index="index"
        :fields="fields"
        :type="settings.type"
        :hasError="bindingRowHasError(binding)"
      )
  .binding-error-message(v-if="missingRequiredFields.length") {{ bindingErrorMessage }}
  .d-flex.additional-data
    .d-flex.additional-data-row
      label.d-flex(for="additionalDataCheckbox")
        input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
        .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
  transition(name="fade")
    .d-flex.other-fields(v-if="checkboxValue")
      .d-flex.fields-wrapper.w-100
        .d-flex.fields-title
          .om-fields-header {{ $t('integrationFlow.shopify.tags') }}

        tag-multiselect(:binding="tagBinding" :type="settings.type")
</template>

<script>
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import { getConfigForIntegration } from '@om/integrations';
  import bindingMixin from '@/mixins/integration/binding';
  import BindingRow from '@/components/IntegrationBindings/Row';
  import TagMultiselect from '@/components/IntegrationBindings/TagMultiselect';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
      TagMultiselect,
    },

    mixins: [bindingMixin],

    data() {
      return {
        fields: [
          {
            name: 'first_name',
            id: 'first_name',
          },
          {
            name: 'last_name',
            id: 'last_name',
          },
          {
            name: 'email',
            id: 'email',
          },
          {
            name: 'phone',
            id: 'phone',
          },
          {
            name: 'Customer tags',
            id: 'tags',
          },
        ],
        defaultCampaignDataFields: [],
      };
    },

    computed: {
      tags() {
        const tagFields = getConfigForIntegration(this.settings.type)?.tagFields || [];

        if (tagFields && tagFields.length) {
          return tagFields;
        }
        return null;
      },
      tagBinding() {
        return this.staticBindings.find((binding) => binding.externalType === '__TAG__');
      },
    },

    watch: {
      checkboxValue(value) {
        if (!value) {
          this.deleteAllAdditionalFields();
        } else if (value) {
          this.addTagsAsStatic();
        }
      },
    },

    methods: {
      addTagsAsStatic() {
        this.tags.forEach((fieldId) => {
          const bind = this.settings.bindings.find((item) => {
            const { externalId, externalType } = item;
            return externalType === '__TAG__' && externalId === fieldId;
          });

          if (!bind) {
            const binding = this.getStaticField();
            binding.externalType = '__TAG__';
            binding.externalId = fieldId;
            this.settings.bindings.push(binding);
          }
        });
      },
    },
  };
</script>

<style lang="sass">
  .shopify-customer
    .step-field-mapping
      .ds-toast-content
        font-size: 12px
        margin-top: 3px
      .other-fields
        .multiselect__tag
          padding-right: 1.75rem !important
        .multiselect__tags-wrap
          max-height: none
</style>
