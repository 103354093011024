<template lang="pug">
.shopify-customer
  .d-flex.step-holder
    stepper(:activeStep="activeContent" @stepClick="validateClickedStep" :steps="alignedSteps")
  .content
    transition(name="fade" mode="out-in")
      step1(
        v-if="this.activeContent === 'setup'"
        :settings.sync="settings"
        :validations="$v.settings"
      )
      step2(
        v-if="this.activeContent === 'fieldMapping'"
        ref="bindings"
        :state="state"
        :settings.sync="settings"
      )
  integration-modal-footer(
    :activeStep="activeContent"
    :steps="alignedSteps"
    :isButtonActive="isValid"
    @click="handleButtonClick"
  )
</template>
<script>
  import { required } from 'vuelidate/lib/validators';
  import Stepper from '@/components/Stepper/index.vue';
  import integrationBase from '@/mixins/integration/integrationBase';
  import IntegrationModalFooter from '../IntegrationModalFooter.vue';
  import Step1 from './step1.vue';
  import Step2 from './step2.vue';

  export default {
    components: {
      IntegrationModalFooter,
      Stepper,
      Step1,
      Step2,
    },

    mixins: [integrationBase],

    data() {
      return {
        activeContent: 'setup',
        integrationType: 'shopifyCustomer',
        steps: ['setup', 'fieldMapping'],
      };
    },

    validations: {
      settings: {
        shopifyDomain: { required },
        name: { required },
      },
    },
  };
</script>
